/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://r6kmv1i8ta.execute-api.us-east-1.amazonaws.com/devfive",
            "region": "us-east-1"
        },
        {
            "name": "restapi",
            "endpoint": "https://j3vza15grl.execute-api.us-east-1.amazonaws.com/devfive",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://akaq7jztbbfwvhmuypk2647fve.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:9dcdaf4d-0156-48a3-966a-f28c3bcb4ef0",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ccoqqflDw",
    "aws_user_pools_web_client_id": "7h9u15rp9d660jqqpsibi7nqcd",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_content_delivery_bucket": "babtl-20240101215828-hostingbucket-devfive",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dfrzpaufvmim6.cloudfront.net"
};


export default awsmobile;
